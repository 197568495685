var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "account-settings-info-view" },
    [
      _c(
        "a-row",
        { attrs: { gutter: 16 } },
        [
          _c(
            "a-col",
            { attrs: { md: 24, lg: 16 } },
            [
              _c(
                "a-form",
                {
                  ref: "form",
                  attrs: {
                    layout: "vertical",
                    rules: _vm.rules,
                    model: _vm.user,
                  },
                },
                [
                  _c(
                    "a-form-item",
                    {
                      ref: "nickName",
                      attrs: {
                        label: "昵称",
                        prop: "nickName",
                        required: true,
                      },
                    },
                    [
                      _c("a-input", {
                        attrs: { placeholder: "给自己起个名字" },
                        model: {
                          value: _vm.user.nickName,
                          callback: function ($$v) {
                            _vm.$set(_vm.user, "nickName", $$v)
                          },
                          expression: "user.nickName",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    { attrs: { label: "手机", required: true } },
                    [
                      _c("a-input", {
                        attrs: { placeholder: "15555555555" },
                        model: {
                          value: _vm.user.phonenumber,
                          callback: function ($$v) {
                            _vm.$set(_vm.user, "phonenumber", $$v)
                          },
                          expression: "user.phonenumber",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    { attrs: { label: "电子邮件", required: true } },
                    [
                      _c("a-input", {
                        attrs: { placeholder: "exp@admin.com" },
                        model: {
                          value: _vm.user.email,
                          callback: function ($$v) {
                            _vm.$set(_vm.user, "email", $$v)
                          },
                          expression: "user.email",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    { attrs: { label: "性别", required: false } },
                    [
                      _c(
                        "a-radio-group",
                        {
                          model: {
                            value: _vm.user.sex,
                            callback: function ($$v) {
                              _vm.$set(_vm.user, "sex", $$v)
                            },
                            expression: "user.sex",
                          },
                        },
                        [
                          _c(
                            "a-radio",
                            { attrs: { value: _vm.sexValue.men } },
                            [_vm._v("男")]
                          ),
                          _c(
                            "a-radio",
                            { attrs: { value: _vm.sexValue.women } },
                            [_vm._v("女")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    [
                      _c(
                        "a-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.submit },
                        },
                        [_vm._v("保存")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "a-col",
            { style: { minHeight: "180px" }, attrs: { md: 24, lg: 8 } },
            [
              _c(
                "div",
                {
                  staticClass: "ant-upload-preview",
                  on: {
                    click: function ($event) {
                      return _vm.$refs.modal.edit(1)
                    },
                  },
                },
                [
                  _c("a-icon", {
                    staticClass: "upload-icon",
                    attrs: { type: "cloud-upload-o" },
                  }),
                  _c(
                    "div",
                    { staticClass: "mask" },
                    [_c("a-icon", { attrs: { type: "plus" } })],
                    1
                  ),
                  _c("img", { attrs: { src: _vm.avatar } }),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _c("avatar-modal", { ref: "modal", on: { ok: _vm.setavatar } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }